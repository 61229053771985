import React from 'react';
import {
  useHistory
} from "react-router-dom";

import {
  Box, Grid, PseudoBox,
  IconButton, Badge,
  Tooltip,
  List, Link,
  Text
} from "@chakra-ui/core";

import { tagBadges, eventsBadge } from './Utility'

const FileItem = ({file, history}) => {
  return (
    <PseudoBox width="100%" borderColor="black" backgroundColor="white" borderBottom={"1px"} _first={{ borderTop: "1px" }} mb={0} >
      <Grid alignItems="center" templateColumns="10fr 10fr 1fr 1fr 1fr" p={1} gap={2}>
        <Tooltip label={file.description || "No description"} placement="right">
          <Box justifySelf="left" ><Text maxWidth={{ base: "10vw", sm: "20vw", lg: "20vw", md: "20vw" }} ml="0.5em" fontSize="lg" isTruncated textTransform="uppercase" fontStyle={file.title ? "normal" : "italic"}>{file.id}. {file.title || file.upload_filename}</Text></Box>
        </Tooltip>
        <Box isInline={true} overflow="hidden">
          <Box isInline={false}>
            <Badge mr={2} >{file.state.toLowerCase() === "done" ? file.can_type : file.state}</Badge>
            {eventsBadge(file.events)}
          </Box>
          {tagBadges(file.tags)}
        </Box>
        <IconButton title="Edit file" aria-label="Edit file" variant="ghost" fontSize="20px" icon="edit" size="md" onClick={() => history.push(`/edit/log/${file.id}`)} />
        <Link isExternal href={file.link} ><IconButton title="Open in Grafana" aria-label="Grafana URL" isDisabled={file.link === ""} variant="ghost" fontSize="20px" icon="external-link" size="md" /></Link>
        <Link isExternal href={`/uploads/${encodeURI(file.server_filename)}`} ><IconButton title="Download .zip" aria-label="Download" isDisabled={file.state.toLowerCase() === "processing"} variant="ghost" fontSize="20px" icon="download" size="md" /></Link>
      </Grid>
    </PseudoBox>)
}

const FileList = ({files, history}) => {
  return (
    <List mb={2}>
      {files.map((file) => (<FileItem file={file} history={history} />))}
    </List>
  )
}

export default function ManualCanLogList({data}) {
  const history = useHistory();

  if (data.length === 0) {
    return (
      <PseudoBox>
        <Text>No files uploaded.</Text>
      </PseudoBox>
    )
  } else {
    return (
      <PseudoBox>
        <Grid borderBottom="solid 1px black" mb={4} alignItems="left" templateColumns="10fr 10fr 4fr" px={4} py={1} gap={2}>
          <Text>ID. Title</Text>
          <Text>Tags</Text>
          <Text textAlign="right" >Operations</Text>
        </Grid>
        <FileList files={data} history={history} />
      </PseudoBox>
    )
  }
}
