import React from 'react';

import {
  PseudoBox, Flex, Box,
  Progress, Text,
  Stat, Heading, StatHelpText, Divider, List, ListItem,
  Button, Link,
} from "@chakra-ui/core";

import { errorText } from './Utility'
import { buttonSettings } from './Styles'
import { StmBootloader } from './STM'

const logger = new StmBootloader()

export default function UpdateLogger({setToastMessage, server}) {
  const [submitting, setSubmitting] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [state, setState] = React.useState('idle')

  if (server?.config?.logger?.file === "") {
    return (
      <Box>
        <PseudoBox mb={4}>
          {errorText('Server does not have logger firmware!')}
        </PseudoBox>
      </Box>
    )
  } else if (typeof navigator.usb !== 'undefined') {
    // logger.autoConnect(autoConnectHandler);

    // function autoConnectHandler(e) {
    //   logger.stopAutoConnect();
    //   connected();
    // }
    
    navigator.usb.addEventListener("disconnect", function(e) {
      logger.onUnexpectedDisconnect(e, function() {
        if (submitting) {
          setSubmitting(false)
          setToastMessage({title: 'Logger Disconnected', status: 'error', duration: 5000})
        }
      });
    });

    function logProgress(done, total) {
      if (state === 'idle' && done !== total) {
        setState('flashing')
      } else if (state === 'erasing' && (done === total)) {
        setState('flashing')
      } else if (state !== 'idle' && (done === total)) {
        setState('idle')
      }
      setProgress((done / total) * 100)
    }

    function logAll(msg) {
      console.log(msg)
    }

    function connected() {
      console.log('connected');
      setSubmitting(true)
      logger.device.logProgress = logProgress
      logger.device.logInfo = logAll
      logger.device.logError = logAll
      logger.device.logWarning = logAll
      logger.device.logContext = logAll
      logger.device.logDebug = logAll
      logger.loadBinary(`/firmware/${server?.config?.logger?.file}`).then((fwArr) => {
        console.log(fwArr)
        if (fwArr !== null) {
          logger.flashInternal(fwArr)
            .then(() => {
              setSubmitting(false)
              setState('idle')
              setToastMessage({title: 'Logger Updated', description: "Please remove the 'DFU' file from the SD Card to use new firmware", status: 'info', duration: 5000})
            }).catch((err) => {
              console.log(err)
              setSubmitting(false)
              setState('idle')
              setToastMessage({title: 'Update Failed', description: `Error: {err}`, status: 'error', duration: 5000})
            })
        } else {
          setSubmitting(false)
          setToastMessage({title: 'Update Failed', description: `Error: fwArr is null`, status: 'error', duration: 5000})
        }
      }).catch((_) => {
        setSubmitting(false)
        setState('idle')
        setToastMessage({title: 'Firmware Missing', description: 'This is a back-end problem!', status: 'error', duration: 5000})
      })
    }

    function clickConnectDownload() {
      logger.detect(() => {
        logger.stopAutoConnect();
        connected();
      }, function(error) {
        console.log(error)
        setToastMessage({title: 'Connection Failed', description: `Failed to connect to STM32 Bootloader: ${error}. Replace driver with WebUSB using Zadig`, status: 'error', duration: 5000})
      });
    }

    const updateProgress = () => {
      if (submitting) {
        return (
          <PseudoBox mb={4} mt={8} px={16}>
            <Text mb={2} textTransform="uppercase" fontStyle="italic" fontSize="xs">{state}...</Text>
            <Progress hasStrip isAnimated color="red" size="md" value={progress} />
          </PseudoBox>
        )
      } else {
        return (<></>)
      }
    }

    return (
      <Box>
        <PseudoBox mb={4}>
          <Stat backgroundColor="gray.100" p={8} mb={4}>
            <Heading mb={2} as="h3" size="md" textTransform="uppercase">How to Use</Heading>
            <List as="ol" styleType="decimal">
              <ListItem><strong>Process only works with Logger FW Version &gt;= 1.4!</strong></ListItem>
              <ListItem><strong>Windows users need to <Link isExternal color="red.800" href={process.env.PUBLIC_URL + '/logger-zadig.png'}>replace the STM32 Bootloader driver with 'WebUSB'</Link> using <Link isExternal color="red.800" href="https://zadig.akeo.ie/">Zadig.</Link></strong></ListItem>
              <ListItem>Create a blank file <strong>"DFU" (or "DFUONCE" to automatically load new application) in the <Link isExternal color="red.800" href={process.env.PUBLIC_URL + '/logger-sd-dfu.png'}>root of the SD Card</Link></strong></ListItem>
              <ListItem>Insert SD Card into logger and connect to computer via USB-C cable.</ListItem>
              <ListItem>The logger should blink <strong>White/Red for 5 cycles</strong> to indicate DFU mode.</ListItem>
              <ListItem>Click "Connect and Download" below, select <strong>"STM BOOTLOADER"</strong> from pop-up menu.</ListItem>
              <ListItem>Wait for progress bar to complete two cycles: erase then program.</ListItem>
              <ListItem><strong>Remove the "DFU"</strong> file from the SD Card, re-insert into logger (if not using "DFUONCE").</ListItem>
              <ListItem>The logger is now updated and ready to use.</ListItem>
            </List>
            <Divider borderColor="red.200" />
            <StatHelpText mb={0}>Server firmware: V{server?.config?.logger?.version} ({server?.config?.logger?.hw_revision}-{server?.config?.logger?.hash})</StatHelpText>
          </Stat>
          <Flex justifyContent="center" alignContent="center">
            <Button {...buttonSettings}
                size="lg"
                isLoading={submitting}
                variant="solid"
                variantColor="red"
                onClick={() => clickConnectDownload()}
              >
              Connect and Update
            </Button>
          </Flex>
        </PseudoBox>
        {updateProgress()}
      </Box>
    )
  } else {
    return (
      <Box>
        <PseudoBox mb={4}>
          {errorText('The Chrome browser is required to use the firmware update tool. Please navigate to this page using Chrome.')}
        </PseudoBox>
      </Box>
    )
  }
}
