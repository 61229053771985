import { theme } from "@chakra-ui/core";

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: "DINOT-Regular",
    heading: "DINOT-Regular"
  },
  colors: {
    ...theme.colors,
    gray: {
      ...theme.colors.gray,
      800: "#282828",
    }
  }
};

export default customTheme
