import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";

import 'react-dropzone-uploader/dist/styles.css'
import './App.css';
import { CSSReset, 
  Grid, Box, Flex, PseudoBox,
  Heading, Spinner,
  Button,
  useToast,
  Link,
  Stack,
  Divider,
  // Breadcrumb, BreadcrumbItem, BreadcrumbLink,
  Alert, AlertIcon, AlertDescription, AlertTitle } from "@chakra-ui/core";

import Dropzone from 'react-dropzone-uploader'

import axios from 'axios'
import { api, useApiRequest, userButtons } from './components/Utility'
import Login from './components/Login'
import Footer from './components/Footer'
import { subHeading, buttonSettings } from './components/Styles'
import EditLog from './components/EditLog'
import ViewFit from './components/ViewFit'
import CanLogList from './components/CanLogList'
import RecentFiles from './components/FileList'
import CreateConfig from './components/CreateConfig'
import UpdateLogger from './components/UpdateLogger'
import SearchLogBox from './components/SearchLogBox'
import SearchResults from './components/SearchResults'
import EditUser from './components/EditUser'
import UserPage from './components/UserPage'

const gridSettings = {
  textAlign: "left",
  color: "black",
  m: 8,
};

function ProtectedRoute({ children, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

// just redirects to home if already logged in
function LoginWrapper({authed, config, setToastMessage, fetchAll}) {
  if (!authed) {
    return (
      <AppWrapper config={config} authed={authed}>
        <Box gridArea="content" {...gridSettings}>
          <Box mx={{ md: "auto" }} maxW={{ md: "40vw" }} >
            <Login getState={fetchAll} setToastMessage={setToastMessage} />
          </Box>
        </Box>
      </AppWrapper>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    )
  }
}

function Logout({logout}) {
  logout()

  return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
  )
}

const AppWrapper = (props) => {
  const { config, authed } = props
  let gridProps = props.gridProps || {
    mx: { md: 4, lg: 12, xl: "20%" },
    gridColumnGap: 4,
    gridTemplateAreas: '"header" "content" "footer"'
  }

  if (authed) {
    return (
      <div className="App">
        <div>
          <CSSReset/>
        </div>
        <Grid {...gridProps}>
          <Box {...gridSettings} gridArea="header" >
            <Heading m="0" fontWeight="400" fontFamily="DINOT-Regular" as="h1" fontSize={{ base: "4rem", md: "6rem" }}>{config?.server?.name}</Heading>
          </Box>
          {props.children}
          <Box {...gridSettings} gridArea="footer" >
            <Footer left="Specialized Europe R&D" logo={true} right={config ? `${config.server.version}:${config?.server?.commit}:${config?.server?.front_end}` : ''} />
          </Box>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className="App">
        <div>
          <CSSReset/>
        </div>
        <Grid {...gridProps}>
          {props.children}
        </Grid>
      </div>
    )
  }
}

function MainApp(props) {
  const { config, authed, logout, data } = props
  let showLogoutButton;

  if (authed) {
    showLogoutButton = userButtons(data.value.user, logout)
  }

  return (
      <AppWrapper config={config} authed={authed}>
        <Box {...gridSettings} gridArea="header" >
          {showLogoutButton}
          <Link href="/" _hover={{ textDecoration: "none" }}><Heading m="0" fontWeight="400" fontFamily="DINOT-Regular" as="h1" fontSize={{ base: "2rem", md: "4rem" }}>SBC-CAN FILES</Heading></Link>
          <Flex mt={4}>
            <Stack spacing={2} isInline >
              <Link href="/import/log" >
                <Button {...buttonSettings}
                  size="sm"
                  variant="ghost"
                  variantColor="gray"
                  type="submit">
                  Import Log
                </Button>
              </Link>
              <Link href="/import/fit" >
                <Button {...buttonSettings}
                  size="sm"
                  variant="ghost"
                  variantColor="gray"
                  type="submit">
                  Import Fit
                </Button>
              </Link>
              <Link href="/create/config" >
                <Button {...buttonSettings}
                  size="sm"
                  variant="ghost"
                  variantColor="gray"
                  type="submit">
                  Logger Config
                </Button>
              </Link>
              <Link href="/logger/update" >
                <Button {...buttonSettings}
                  size="sm"
                  variant="ghost"
                  variantColor="gray"
                  type="submit">
                  Logger Update
                </Button>
              </Link>
            </Stack>
          </Flex>
          <Divider borderColor="red.200" />
        </Box>

        <Box {...gridSettings} gridArea="content" >
          {props.children}
        </Box>
      </AppWrapper>
  )
}

function App() {
  const [{ data, loading, error, fetchData: fetchState }] = useApiRequest('/state');
  const [toastMessage, setToastMessage] = React.useState()
  const toast = useToast();

  const config = data?.value?.config
  const authed = data?.value?.authed

  React.useEffect(() => {
    if (toastMessage) {
      const { title, description, status, duration } = toastMessage;

      toast({
        title,
        description,
        status,
        duration,
        isClosable: true
      });
    }
  }, [toastMessage, toast]);

  const fetchAll = () => {
    fetchState()
  }

  const logout = () => {
    axios.get('/auth/logout').then(() => {
      fetchAll()
      setToastMessage({title: 'Logout Successful', status: 'success', duration: 5000})
    }).catch((err) => {
      setToastMessage({title: 'Logout Failed', description: err?.response?.data?.error, status: 'error', duration: 5000})
    })
  }

  function NavButtons() {
    return (
      <Flex justifyContent="center" alignContent="center">
        <Link m={4} href='/import/log'><Button {...buttonSettings} variantColor="red">Import Log</Button></Link>
        <Link m={4} href='/import/fit'><Button {...buttonSettings} variantColor="red">Import Fit</Button></Link>
        <Link m={4} href='/create/config'><Button {...buttonSettings} variantColor="red">Logger Config</Button></Link>
        <Link m={4} href='/logger/update'><Button {...buttonSettings} variantColor="red">Logger Update</Button></Link>
      </Flex>
    )
  }


  if (loading.value) {
    return (
      <AppWrapper config={config} authed={authed}>
        <Box position="relative" {...gridSettings} gridArea="header" >
          <Heading m="0" fontWeight="400" fontFamily="DINOT-Regular" as="h1" fontSize={{ base: "2rem", md: "4rem" }}>LOADING</Heading>
        </Box>
        <Box {...gridSettings} gridArea="content" >
          <Flex justifyContent="center" >
            <Spinner size="xl"/>
          </Flex>
        </Box>
      </AppWrapper>
    )
  } else if (error.value) {
    return (
      <AppWrapper config={config} authed={authed}>
          <Box {...gridSettings} gridArea="header" >
            <Heading m="0" fontWeight="400" fontFamily="DINOT-Regular" as="h1" fontSize={{ base: "2rem", md: "4rem" }}>ERROR</Heading>
          </Box>
          <Box {...gridSettings} gridArea="content" >
            <Alert status="error"
              variant="top-accent"
              flexDirection="column"
              justifyContent="center"
              // textAlign="left"
              // mx={{ lg: 32, base: 0 }}
            >
              <AlertIcon size="40px" m={4} />
              <AlertTitle fontSize="lg" mb={4} textTransform="uppercase" >Server Connection</AlertTitle>
              <AlertDescription textAlign="justify" fontSize="md" mb={4}>Unable to communicate with back-end!</AlertDescription>
            </Alert>
          </Box>
      </AppWrapper>
    )
  } else {
    return (
      <Router>
        <Switch>
          <ProtectedRoute authed={authed} path="/edit/log/:id">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Edit Log File
              </PseudoBox>
              <EditLog setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/user">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Current User
              </PseudoBox>
              <UserPage setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/edit/user/:id">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Edit User
              </PseudoBox>
              <EditUser setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/view/fit/:id">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                View Fit File
              </PseudoBox>
              <ViewFit setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/import/fit">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Upload Fit File
              </PseudoBox>
              <FitDropper setToastMessage={setToastMessage} />
              <PseudoBox {...subHeading} mt={0}>
                Recent Fit Files
              </PseudoBox>
              <RecentFiles type="fit" />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/import/log">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Upload Log File
              </PseudoBox>
              <LogDropper setToastMessage={setToastMessage} />
              <PseudoBox {...subHeading} mt={0}>
                Recent Log Files
              </PseudoBox>
              <SearchLogBox />
              <CanLogList />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/import/logs">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Upload Multiple Log Files
              </PseudoBox>
              <LogMultiDropper setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} path="/search/logs">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading}>
                Log File Search Results
              </PseudoBox>
              <SearchResults />
            </MainApp>
          </ProtectedRoute>

          <Route path="/create/config">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading} mt={0}>
                Create Logger Config
              </PseudoBox>
              <CreateConfig setToastMessage={setToastMessage} />
            </MainApp>
          </Route>

          <ProtectedRoute authed={authed} path="/logger/update">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox {...subHeading} mt={0}>
                Update Logger
              </PseudoBox>
              <UpdateLogger server={data?.value} setToastMessage={setToastMessage} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} exact path="/logout">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <Logout logout={logout} />
            </MainApp>
          </ProtectedRoute>

          <ProtectedRoute authed={authed} exact path="/">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox>
                <NavButtons />
              </PseudoBox>
            </MainApp>
          </ProtectedRoute>

          <Route path="/login">
            <LoginWrapper config={config} authed={authed} setToastMessage={setToastMessage} fetchAll={fetchAll} />
          </Route>

          <ProtectedRoute authed={authed} path="/*">
            <MainApp config={config} data={data} logout={logout} authed={authed} >
              <PseudoBox>
                <Alert status="error"
                variant="top-accent"
                flexDirection="column"
                justifyContent="center"
              >
                  <AlertIcon size="40px" m={4} />
                  <AlertTitle fontSize="lg" mb={4} textTransform="uppercase" >Not found</AlertTitle>
                  <AlertDescription textAlign="justify" fontSize="md" mb={4}>No route at this address!</AlertDescription>
                </Alert>
              </PseudoBox>
            </MainApp>
          </ProtectedRoute>

        </Switch>
      </Router>
    )
  }
}

function LogDropper({setToastMessage}) {
  var server_file
  const history = useHistory();

  const Standard = () => {
    const getUploadParams = () => {
      return { url: `${process.env.PUBLIC_URL}/api/upload/log?events` }
    }

    const handleChangeStatus = ({ meta, xhr }, status) => {
      switch (status) {
        case 'done':
          server_file = JSON.parse(xhr.responseText).log
          if (server_file.id) {
            history.push(`/edit/log/${server_file.id}`);
            setToastMessage({title: `File ${server_file.id} Uploaded`, status: 'success', duration: 3000})
          } else {
            setToastMessage({title: 'Upload Failed', description: 'Is the file a valid SBC-CAN log file?', status: 'error', duration: 5000})
          }
          break
        case 'removed':
          if (server_file !== undefined) {
            if (server_file.id) {
              api.delete(`/logs/${server_file?.id}`).then(() => {
                var res = JSON.parse(xhr.responseText)
                console.log(res)
              })
            }
          }
          break
        case 'aborted':
          setToastMessage({title: 'Upload Aborted', status: 'warning', duration: 3000})
          break
        case 'error_file_size':
          setToastMessage({title: 'Upload Failed', description: 'File size if too large! Max file size 500 MB', status: 'error', duration: 5000})
          break
        case 'exception_upload':
        case 'error_upload':
          // this will re-render and loose files but it's ok
          setToastMessage({title: 'Upload Failed', description: 'Is the file a valid SBC-CAN log file?', status: 'error', duration: 5000})
          break
        default:
          break
      }
      console.log(status, meta)
    }

    return (
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      maxSizeBytes={2000 * 1024 * 1024}
      accept={".log, .LOG, .dat, .DAT, .zip"}
      inputContent="Drop an SBC-CAN .log file"
      styles={{ dropzone: { minHeight: 100, maxHeight: 200 } }}
    />
    )
  }

  return (<Standard />)
}

function LogMultiDropper({setToastMessage}) {
  const Standard = () => {
    const getUploadParams = () => {
      return { url: `${process.env.PUBLIC_URL}/api/upload/log?events` }
    }

    const handleSubmit = (files, allFiles) => {
      allFiles.forEach(f => f.remove())
      setToastMessage({title: `Files Uploaded`, status: 'success', duration: 3000})
    }

    return (
      <Dropzone
      getUploadParams={getUploadParams}
      // onChangeStatus={handleChangeStatus}
      multiple={true}
      onSubmit={handleSubmit}
      maxSizeBytes={2000 * 1024 * 1024}
      accept={".log, .LOG, .dat, .DAT, .zip"}
      inputContent="Drop an SBC-CAN .log file"
      styles={{ dropzone: { minHeight: 100 } }}
    />
    )
  }

  return (<Standard />)
}

function FitDropper({setToastMessage}) {
  var server_file
  const history = useHistory();

  const Standard = () => {
    // TODO this won't work in development as will route to yarn server
    const getUploadParams = () => {
      return { url: `${process.env.PUBLIC_URL}/api/upload/fit` }
    }

    const handleChangeStatus = ({ meta, xhr }, status) => {
      switch (status) {
        case 'done':
          server_file = JSON.parse(xhr.responseText).fit
          if (server_file.id) {
            history.push(`/view/fit/${server_file.id}`);
            setToastMessage({title: 'File Uploaded', status: 'success', duration: 3000})
          } else {
            setToastMessage({title: 'Upload Failed', description: 'Is the file a valid fit file?', status: 'error', duration: 5000})
          }
          break
        case 'removed':
          if (server_file !== undefined) {
            if (server_file.id) {
              api.delete(`/logs/${server_file?.id}`).then(() => {
                var res = JSON.parse(xhr.responseText)
                console.log(res)
              })
            }
          }
          break
        case 'exception_upload':
        case 'error_upload':
          // this will re-render and loose files but it's ok
          setToastMessage({title: 'Upload Failed', description: 'Is the file a valid fit file?', status: 'error', duration: 5000})
          break
        default:
          break
      }
      console.log(status, meta)
    }

    return (
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      maxSizeBytes={50 * 1024 * 1024}
      accept={".fit, .FIT, .bin"}
      inputContent="Drop .fit file"
      styles={{ dropzone: { minHeight: 100, maxHeight: 200 } }}
    />
    )
  }

  return (<Standard />)
}

export default App;
