// TODO checkbox sensor selector
// TODO filter tester
import React from 'react';

import {
  PseudoBox, Flex, Box,
  Stack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input, Checkbox,
  Select,
  Button,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stat, StatHelpText,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";

import { api, filterTest } from './Utility'
import { buttonSettings, labelSettings, inputSettings, checkboxSettings } from './Styles'
import fileDownload from 'js-file-download';

export default function CreateConfig({setToastMessage}) {
  const { handleSubmit, errors, register, setValue, getValues } = useForm();
  const [submitting, setSubmitting] = React.useState(false)
  const [filterTestValue, setFilterTestValue] = React.useState(-1)
  const [filterState, setFilterState] = React.useState(true)

  const onSubmit = (d) => {
    setSubmitting(true)
    switch (parseInt(d['can_baud_select'])) {
      case 500:
        d['can_baud'] = 1
        break
      case 1000:
        d['can_baud'] = 2
        break
      default:
      case 250:
        d['can_baud'] = 0
        break
    }
    console.log(d)
    api({ method: 'post', url: `create/config`, data: d, responseType: 'blob'} ).then((res) => {
      fileDownload(res.data, "CONFIG")
      setSubmitting(false)
    }).catch((err) => {
      setSubmitting(false)
      setToastMessage({title: 'Failed', description: `Something went wrong creating the config file: ${err?.response?.data?.error}`, status: 'error', duration: 5000})
    })
  }

  const checkboxChanged = (e) => {
    var mask = getValues()
    mask = parseInt(mask.sensors)
    switch (e.target.name) {
      case 'saccelerometer':
        if (e.target.checked) {
          setValue('sensors', mask + 1)
        } else {
          setValue('sensors', mask - 1)
        }
        break
      case 'svoltage':
        if (e.target.checked) {
          setValue('sensors', mask + 2)
        } else {
          setValue('sensors', mask - 2)
        }
        break
      case 'sdigital':
        if (e.target.checked) {
          setValue('sensors', mask + 4)
        } else {
          setValue('sensors', mask - 4)
        }
        break
      case 'sbattery':
        if (e.target.checked) {
          setValue('sensors', mask + 8)
          setValue('sensor_broadcast', 1)
          setValue('sensor_rate', 10000)
        } else {
          setValue('sensors', mask - 8)
          setValue('sensor_broadcast', 0)
          setValue('sensor_rate', 20)
        }
        break
      case 'sfrequency':
        if (e.target.checked) {
          setValue('sensors', mask + 16)
        } else {
          setValue('sensors', mask - 16)
        }
        break
      default:
        break
    }
  }

  const filterTestInputUpdate = (e) => {
    var value = parseInt(e.target.value)
    console.log(value)
    if (value >= 0) {
      setFilterTestValue(value)
      filterTestUpdate(value)
    } else {
      setFilterTestValue(-1)
      setFilterState(false);
    }
  }

  const filterTestUpdate = (test = filterTestValue) => {
    if (test >= 0) {
      var values = getValues()
      var filterId = parseInt(values.filter_id)
      var filterMask = parseInt(values.filter_id_mask)
      var filterInvert = values.filter_invert

      setFilterState(!!filterTest(test, filterId, filterMask, filterInvert))
    }
  }

  var filterInputSettings = {
    ...inputSettings,
    backgroundColor: "gray.200"
  }

  if (filterState && filterTestValue >= 0) {
    filterInputSettings.backgroundColor = "green.400"
    filterInputSettings.color = "white"
  } else if (!filterState && filterTestValue >= 0) {
    filterInputSettings.backgroundColor = "red.400"
    filterInputSettings.color = "white"
  }

  const bitrates = ["250", "500", "1000"]
  let bitrateOptions = []
  bitrates.map((t, i) => {
    return bitrateOptions.push(
      <option key={`t-select-${i}`} value={t}>{t}</option>
    )
  })

  return (
    <Box>
      <PseudoBox mb={4}>
        <FormLabel {...labelSettings} htmlFor="filter_id">Filter Tester</FormLabel>
        <Input {...filterInputSettings} errorBorderColor="crimson" placeholder="Test ID (Base 10)" onChange={e => filterTestInputUpdate(e)} />
        <Stat backgroundColor="gray.100" p={2} mb={4}>
          <StatHelpText fontWeight="bold"  mb={0}>{"((RxId & Filter Mask) == Filter Id) ^ Filter Invert"}</StatHelpText>
        </Stat>
      </PseudoBox>
      <Divider borderColor="red.200" />
      <PseudoBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.filter_id}>
            <FormLabel {...labelSettings} htmlFor="filter_id">Filter ID (Base 10)</FormLabel>
            <Input {...inputSettings}
            name="filter_id"
            defaultValue={0}
            type="number"
            onChange={() => filterTestUpdate()}
            ref={register({ required: true, min: 0, max: 65536 })} />
              <FormErrorMessage>
                {errors.filter_id && "Invalid value"}
              </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.filter_id_mask}>
            <FormLabel {...labelSettings} htmlFor="filter_id_mask">Filter Mask (Base 10)</FormLabel>
            <Input {...inputSettings}
            name="filter_id_mask"
            defaultValue={0}
            type="number"
            onChange={() => filterTestUpdate()}
            ref={register({ required: true, min: 0, max: 65536 })} />
              <FormErrorMessage>
                {errors.filter_id_mask && "Invalid value"}
              </FormErrorMessage>
          </FormControl>
          <Stack spacing={4} isInline>
            <Checkbox {...inputSettings} {...checkboxSettings} name="filter_invert" defaultIsChecked={false} onChange={() => filterTestUpdate()} ref={register()}>Filter Invert</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="log_can_rx" defaultIsChecked={true} ref={register()}>Log CAN Rx</Checkbox>
          </Stack>
          <Divider borderColor="red.200" />
          <FormControl isInvalid={errors.can_baud_select}>
            <FormLabel {...labelSettings} htmlFor="title">CAN bitrate kb/s</FormLabel>
            <Select {...inputSettings} defaultValue="250" name="can_baud_select" ref={register()}>
              {bitrateOptions}
            </Select>
            <FormErrorMessage>
              {errors.can_baud_select && "Invalid CAN bitrate"}
            </FormErrorMessage>
          </FormControl>
          <Divider borderColor="red.200" />
            <FormLabel {...labelSettings} htmlFor="sensors">Sensors</FormLabel>
          <Stack spacing={4} isInline>
            <Checkbox {...inputSettings} {...checkboxSettings} name="saccelerometer" defaultIsChecked={false} onChange={e => checkboxChanged(e)} >LSM6DS</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="svoltage" defaultIsChecked={false} onChange={e => checkboxChanged(e)} >Voltage Sense</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="sdigital" defaultIsChecked={false} onChange={e => checkboxChanged(e)} >Digital Sense</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="sbattery" defaultIsChecked={false} onChange={e => checkboxChanged(e)} >Battery Temp</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="sfrequency" defaultIsChecked={false} onChange={e => checkboxChanged(e)} >Freq Sense</Checkbox>
          </Stack>
          <FormControl isInvalid={errors.sensors}>
            <FormLabel {...labelSettings} htmlFor="sensors">Sensor Mask</FormLabel>
            <Input {...inputSettings}
            name="sensors"
            defaultValue={0}
            isDisabled={true}
            type="number"
            ref={register({ required: true, min: 0, max: 65535 })} />
              <FormErrorMessage>
                {errors.sensors && "Invalid value"}
              </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.sensor_rate} >
            <FormLabel {...labelSettings} htmlFor="sensor_rate">Sensor Update Rate (ms)</FormLabel>
            <NumberInput {...inputSettings} name="sensor_rate" step={10} defaultValue={20} min={2} max={65335} >
              <NumberInputField name="sensor_rate" ref={register({required: true, min: 2, max: 65335})} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <Stack spacing={4} isInline>
            <Checkbox {...inputSettings} {...checkboxSettings} name="sensor_broadcast" defaultIsChecked={false}ref={register()}>Sensor Broadcast</Checkbox>
          </Stack>
          <Divider borderColor="red.200" />
          <FormControl isInvalid={errors.speed_threshold} >
            <FormLabel {...labelSettings} htmlFor="speed_threshold">Speed Threshold (km/h)</FormLabel>
            <NumberInput {...inputSettings} name="speed_threshold" step={1} defaultValue={0} min={0} max={100} >
              <NumberInputField name="speed_threshold" ref={register({required: true, min: 0, max: 100})} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <Divider borderColor="red.200" />
          <FormControl isInvalid={errors.logger_id} >
            <FormLabel {...labelSettings} htmlFor="logger_id">Logger ID</FormLabel>
            <Input {...inputSettings} name="logger_id" defaultValue={0} type="number" ref={register({required: true, min: 0, max: 65535})} ></Input>
          </FormControl>
          <Stack spacing={4} isInline>
            <Checkbox {...inputSettings} {...checkboxSettings} name="write_binary" defaultIsChecked={false} ref={register()}>Write Binary .DAT File</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="simplo_rtc" defaultIsChecked={false} ref={register()}>Use Simplo Battery RTC not local</Checkbox>
          </Stack>
          <FormControl isInvalid={errors.buffer_write} >
            <FormLabel {...labelSettings} htmlFor="buffer_write">Buffered Aligned Write (0 off; 1 trimmed; 2 padded)</FormLabel>
            <Input {...inputSettings} name="buffer_write" defaultValue={1} type="number" ref={register({required: true, min: 0, max: 2})} ></Input>
          </FormControl>
          <FormControl isInvalid={errors.utc_hours} >
            <FormLabel {...labelSettings} htmlFor="utc_hours">UTC Offset (Hours) - for filesystem only</FormLabel>
            <Input {...inputSettings} name="utc_hours" defaultValue={0} type="number" ref={register({required: true, min: -24, max: 24})} ></Input>
          </FormControl>
          <Stack spacing={4} isInline>
            <Checkbox {...inputSettings} {...checkboxSettings} name="fast_boot" defaultIsChecked={false} ref={register()}>Boot to logging as fast as possible (no LED status)</Checkbox>
            <Checkbox {...inputSettings} {...checkboxSettings} name="no_msd" defaultIsChecked={false} ref={register()}>No MSD when USB</Checkbox>
          </Stack>
          <Flex justifyContent="right" alignContent="right">
            <Stack mt={8} spacing={4} isInline >
              <Button {...buttonSettings}
                size="lg"
                isLoading={submitting}
                // isDisabled={!formState.dirty}
                variant="solid"
                variantColor="red"
                type="submit">
                Download
              </Button>
            </Stack>
          </Flex>
        </form>
      </PseudoBox>
    </Box>
  )
}
