var WebDFU = require("webdfu");
var DFU = WebDFU.DFU;
var DFUse = WebDFU.DFUse;

const AUTOCONNECT_DELAY = 1000;

export class StmBootloader {
  constructor() {
    this.device = null;
    this.transferSize = 1024;
    this.manifestationTolerant = false;
    this.autoconnectId = null;
  }

  /**

  /**
   * Flash buffer to internal flash.
   *
   * @param   buffer      ArrayBuffer to flash.
   */
  async flashInternal(buffer) {
    this.device.startAddress = 0x08000000;
    try {
      let status = await this.device.getStatus();
      if (status.state === DFU.dfuERROR) {
        await this.device.clearStatus();
      }
    } 
    catch (error) {
      this.device.logWarning("Failed to clear status");
    }
    await this.device.do_download(this.transferSize, buffer, true);
  }

  async loadBinary(file) {
    return new Promise(function(resolve, reject) {
      var req = new XMLHttpRequest();
      req.open('GET', file, true);
      req.responseType = "blob";

      req.onload = async function(_) {
        if(req.status === 404) {
          reject('File does not exist!')
        }

        let reader = new FileReader();
        reader.onload = async function() {
          resolve(reader.result);
        }
        reader.readAsArrayBuffer(req.response);
      }

      req.send()
    })
  }

  async __getDFUDescriptorProperties(device) {
    // Attempt to read the DFU functional descriptor
    // TODO: read the selected configuration's descriptor
    return device.readConfigurationDescriptor(0).then(
      data => {
        let configDesc = DFU.parseConfigurationDescriptor(data);
        let funcDesc = null;
        let configValue = device.settings.configuration.configurationValue;
        if (configDesc.bConfigurationValue === configValue) {
          for (let desc of configDesc.descriptors) {
            if (desc.bDescriptorType === 0x21 && desc.hasOwnProperty("bcdDFUVersion")) {
              funcDesc = desc;
              break;
            }
          }
        }

        if (funcDesc) {
          return {
            WillDetach:            ((funcDesc.bmAttributes & 0x08) !== 0),
            ManifestationTolerant: ((funcDesc.bmAttributes & 0x04) !== 0),
            CanUpload:             ((funcDesc.bmAttributes & 0x02) !== 0),
            CanDnload:             ((funcDesc.bmAttributes & 0x01) !== 0),
            TransferSize:          funcDesc.wTransferSize,
            DetachTimeOut:         funcDesc.wDetachTimeOut,
            DFUVersion:            funcDesc.bcdDFUVersion
          };
        } else {
          return {};
        }
      },
      error => {}
    );
  }

  /**
   * Detect a STM bootloader
   *
   * @param   successCallback     Callback in case of success.
   * @param   errorCallback       Callback in case of error.
   */
  async detect(successCallback, errorCallback) {
    var _this = this;
    navigator.usb.requestDevice({ 'filters': [{'vendorId': 0x0483, 'productId': 0xdf11}]}).then(
      async selectedDevice => {
        let interfaces = DFU.findDeviceDfuInterfaces(selectedDevice);
        await _this.__fixInterfaceNames(selectedDevice, interfaces);
        _this.device = await _this.__connect(new DFU.Device(selectedDevice, interfaces[0]));

        successCallback();
      }
    ).catch(error => {
      errorCallback(error);
    });
  }

  /**
   * Connect to a WebDFU device.
   *
   * @param   device      The WebUSB device to connect to.
   */
  async __connect(device) {
    try {
      await device.open();
    } catch (error) {
      // this.installInstance.calculatorError(true, error);
      throw error;
    }

    // Attempt to parse the DFU functional descriptor
    let desc = {};
    try {
      desc = await this.__getDFUDescriptorProperties(device);
    } catch (error) {
      // this.installInstance.calculatorError(true, error);
      throw error;
    }

    if (desc && Object.keys(desc).length > 0) {
      device.properties = desc;
      this.transferSize = desc.TransferSize;
      if (desc.CanDnload) {
        this.manifestationTolerant = desc.ManifestationTolerant;
      }

      if (desc.DFUVersion === 0x011a && device.settings.alternate.interfaceProtocol === 0x02) {
        device = new DFUse.Device(device.device_, device.settings);
        if (device.memoryInfo) {
          // We have to add RAM manually, because the device doesn't expose that normally
          device.memoryInfo.segments.unshift({
            start: 0x20000000,
            sectorSize: 1024,
            end: 0x20040000,
            readable: true,
            erasable: false,
            writable: true
          });
        }
      }
    }

    // Bind logging methods
    device.logDebug = console.log;
    device.logInfo = console.info;
    device.logWarning = console.warn;
    device.logError = console.error;
    device.logProgress = console.log;

    return device;
  }

  async __autoConnectDevice(device) {
    let interfaces = DFU.findDeviceDfuInterfaces(device.device_);
    await this.__fixInterfaceNames(device.device_, interfaces);
    device = await this.__connect(new DFU.Device(device.device_, interfaces[0]));
    console.log("Autoconnected to device:", device);
    return device;
  }

  /**
   * Autoconnect a STM Bootloader
   *
   * @param   serial      Serial number. If ommited, any will work.
   */
  autoConnect(callback, serial) {
    var _this = this;
    var vid = 0x0483, pid = 0xdf11;

    DFU.findAllDfuInterfaces().then(async dfu_devices => {
      let matching_devices = _this.__findMatchingDevices(vid, pid, serial, dfu_devices);

      if (matching_devices.length !== 0) {
        this.stopAutoConnect();

        this.device = await this.__autoConnectDevice(matching_devices[0]);

        await callback();
      }
    });

    this.autoconnectId = setTimeout(this.autoConnect.bind(this, callback, serial), AUTOCONNECT_DELAY);
  }

  /**
   * Stop autoconnection.
   */
  stopAutoConnect() {
    if (this.autoconnectId === null) return;

    clearTimeout(this.autoconnectId);

    this.autoconnectId = null;
  }

  async __fixInterfaceNames(device_, interfaces) {
    // Check if any interface names were not read correctly
    if (interfaces.some(intf => (intf.name === null))) {
      // Manually retrieve the interface name string descriptors
      let tempDevice = new DFU.Device(device_, interfaces[0]);
      await tempDevice.device_.open();
      let mapping = await tempDevice.readInterfaceNames();
      await tempDevice.close();

      for (let intf of interfaces) {
        if (intf.name === null) {
          let configIndex = intf.configuration.configurationValue;
          let intfNumber = intf["interface"].interfaceNumber;
          let alt = intf.alternate.alternateSetting;
          intf.name = mapping[configIndex][intfNumber][alt];
        }
      }
    }
  }

  __findMatchingDevices(vid, pid, serial, dfu_devices) {
    let matching_devices = [];
    for (let dfu_device of dfu_devices) {
      if (serial) {
        if (dfu_device.device_.serialNumber === serial) {
          matching_devices.push(dfu_device);
        }
      } else {
        if (
          (!pid && vid > 0 && dfu_device.device_.vendorId  === vid) ||
          (!vid && pid > 0 && dfu_device.device_.productId === pid) ||
          (vid > 0 && pid > 0 && dfu_device.device_.vendorId  === vid && dfu_device.device_.productId === pid)
        )
        {
          matching_devices.push(dfu_device);
        }
      }
    }

    return matching_devices;
  }

  onUnexpectedDisconnect(event, callback) {
    if (this.device !== null && this.device.device_ !== null) {
      if (this.device.device_ === event.device) {
        this.device.disconnected = true;
        callback(event);
        this.device = null;
      }
    }
  }
}
