import React from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage, FormControl,
  Input, Checkbox,
  Button,
  PseudoBox,
  Box,
} from "@chakra-ui/core";
import axios from 'axios'
import {
  useHistory,
  useLocation
} from "react-router-dom";

import { subHeading, inputSettings, checkboxSettings, buttonSettings } from './Styles'

export default function Login({ getState, setToastMessage }) {
  const { register, handleSubmit, errors } = useForm();
  const [submitting, setSubmitting] = React.useState(false)
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };


  const onSubmit = (data) => {
    const form = new FormData()
    form.append('username', data.username)
    form.append('password', data.password)
    form.append('remember', data.remember)

    axios.post('/auth/login', form).then(() => {
      setToastMessage({title: 'Login Successful', description: 'Loading requested page', status: 'success', duration: 5000})
      // get the auth state (loading/error) will re-render page
      getState()
      // go back to where we came from
      history.replace(from)
    }).catch((err) => {
      setToastMessage({title: 'Login Failed', description: err?.response?.data?.error, status: 'error', duration: 5000})
      setSubmitting(false)
    })
  }

  return (
    <Box>
      <PseudoBox {...subHeading}>
        Login
      </PseudoBox>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.username !== undefined}>
          <Input {...inputSettings} type="text" placeholder="Username" name="username" ref={register({required: { value: true, message: "Please enter a username"}, maxLength: { value: 100, message: "Invalid username"}}) } />
          <FormErrorMessage {...inputSettings} >{errors?.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password !== undefined}>
          <Input {...inputSettings} type="password" placeholder="Password" name="password" ref={register({required: { value: true, message: "Please enter a password"}, maxLength: { value: 50, message: "Invalid password"}}) } />
          <FormErrorMessage {...inputSettings} >{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <Checkbox {...inputSettings} {...checkboxSettings} defaultIsChecked={true} name="remember" ref={register()}>Remember</Checkbox>
        <Button {...buttonSettings}
          isLoading={submitting}
          isFullWidth="true"
          variant="solid"
          variantColor="red"
          type="submit"
          borderColor="black"
        >
          Login
        </Button>
      </form>
    </Box>
  );
}
