import React from 'react';
import {
  useParams,
} from "react-router-dom";

import {
  PseudoBox, Flex, Box,
  Stack,
  FormErrorMessage,
  FormLabel,
  FormControl, Select,
  Input,
  Button,
  Checkbox
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";

import { api, useApiId, useApiRequest, loadingSpinner, errorText } from './Utility'
import { buttonSettings, labelSettings, inputSettings, checkboxSettings } from './Styles'

export default function EditUser( { setToastMessage } ) {
  const { id } = useParams();
  const [{ data, loading, error } ] = useApiId('user', id);
  const [{ data: user, loading: userLoading, error: userError }] = useApiRequest('user');
  const [{ data: tz, loading: tzLoading, error: tzError }] = useApiRequest('timezones');
  const [{ data: gp, loading: gpLoading, error: gpError }] = useApiRequest('groups');
  const { handleSubmit, errors, register, formState, watch, reset  } = useForm();
  const [submitting, setSubmitting] = React.useState(false)
  const password = React.useRef({});
  password.current = watch("new_password", "");

  const onSubmit = (d) => {
    delete d.confirm_password
    d.group_id = parseInt(d.group_id)
    if (d.new_password === "") {
      delete d.password
      delete d.new_password
    }
    setSubmitting(true)
    api.patch(`user/${id}`, d).then((res) => {
      setSubmitting(false)
      loading.setIsLoading(true)
      console.log(res.data)
      data.setData(res.data)
      reset()
      loading.setIsLoading(false)
      // fetchData()
      setToastMessage({title: 'User Saved', description: `User ${id} updated`, status: 'success', duration: 3000})
    }).catch(() => {
      setSubmitting(false)
      setToastMessage({title: 'Update Failed', description: `Something went wrong saving the user details, please try again`, status: 'error', duration: 5000})
    })
  }

  if (loading.value || gpLoading.value || tzLoading.value || userLoading.value) {
    return (
      <>
        {loadingSpinner('Loading...')}
      </>
    )
  } else if (!user.value.admin) {
    return (
      <>
        {errorText('Only admins can edit users!')}
      </>
    )
  } else if (error.value || gpError.value || tzError.value || userError.value) {
    return (
      <>
        {errorText('Error loading or updating user details!')}
      </>
    )
  } else {

    let groupOptions = []
    gp.value.map((g, i) => {
      return groupOptions.push(
        <option key={`g-select-${i}`} value={g.id}>{g.name}</option>
      )
    })

    let timezoneOptions = []
    tz.value.map((tz, i) => {
      return timezoneOptions.push(
        <option key={`tz-select-${i}`} value={tz}>{tz}</option>
      )
    })

    return (
      <Box>
        <PseudoBox>
          <form onSubmit={handleSubmit(onSubmit)}>

            <FormControl isInvalid={errors.name}>
              <FormLabel {...labelSettings} htmlFor="name">Name</FormLabel>
              <Input {...inputSettings}
              name="name"
              // isDisabled={!data.value.admin}
              defaultValue={data.value.name}
              type="text"
              ref={register({ required: true, min: 8, max: 50 })} />
                <FormErrorMessage>
                  {errors.name && "Invalid name"}
                </FormErrorMessage>
            </FormControl>

            <Stack spacing={4} isInline>
              <Checkbox {...inputSettings} {...checkboxSettings} name="admin" defaultIsChecked={data.value.admin} ref={register()} >Admin</Checkbox>
            </Stack>

            <FormControl isInvalid={errors.group}>
              <FormLabel {...labelSettings} htmlFor="group">Group</FormLabel>
              <Select {...inputSettings} defaultValue={data.value.group_id} name="group_id" ref={register({ required: true })}>
                {groupOptions}
              </Select>
            </FormControl>

            <FormControl isInvalid={errors.timezone}>
              <FormLabel {...labelSettings} htmlFor="tz">Timezone</FormLabel>
              <Select {...inputSettings} defaultValue={data.value.tz} name="tz" ref={register({ required: true })}>
                {timezoneOptions}
              </Select>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <FormLabel {...labelSettings} htmlFor="password">Current Password</FormLabel>
              <Input {...inputSettings}
              name="password"
              type="password"
              ref={register({ required: false, min: 8, max: 100 })} />
                <FormErrorMessage>
                  {errors.password && "Invalid current password"}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.new_password}>
              <FormLabel {...labelSettings} htmlFor="new_password">New Password</FormLabel>
              <Input {...inputSettings}
              name="new_password"
              type="password"
              ref={register({ required: false, min: 8, max: 100 })} />
                <FormErrorMessage>
                  {errors.new_password && "Invalid new password"}
                </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.confirm_password}>
              <FormLabel {...labelSettings} htmlFor="confirm_password">Confirm New Password</FormLabel>
              <Input {...inputSettings}
              name="confirm_password"
              type="password"
              ref={register({
                validate: value =>
                value === password.current || "The passwords do not match"
              })}
            />
                <FormErrorMessage>
                  {errors.confirm_password && errors.confirm_password.message}
                </FormErrorMessage>
            </FormControl>
            <Flex justifyContent="right" alignContent="right">
              <Stack mt={8} spacing={4} isInline >
                <Button {...buttonSettings}
                  size="lg"
                  isLoading={submitting}
                  isDisabled={!formState.dirty}
                  variant="solid"
                  variantColor="red"
                  type="submit">
                  Update
                </Button>
              </Stack>
            </Flex>
          </form>
        </PseudoBox>
      </Box>
    )
  }
}
