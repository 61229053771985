import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Text, Stack, Link, Flex, Box, Spinner, Icon, Badge, Button } from "@chakra-ui/core";

import { buttonSettings } from './Styles'

export const api = axios.create({
  baseURL: '/api',
  timeout: 60000
})

export const useManualApiRequest = () => {
  const [loading, setIsLoading] = useState(false);

  const doFetch = async (url, data, callback, type) => {
    let crud = type === 'get' ? api : api.post
    setIsLoading(true);
    try {
      const result = await crud(url, data);
      if (callback) callback(null, result.data)
    } catch (error) {
      if (callback) callback(error)
      console.log(error.response)
    }
    setIsLoading(false);
  };

  return [{ loading }, doFetch];
}

export const useApiRequest = (initialUrl, initialData) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [loading, setIsLoading] = useState(true);
  const [error, setIsError] = useState(false);
  const [requestFetch, setRequestFetch] = useState(false);
  // ugly toggle set during fetch start to envoke fetch without making fetchData external
  const fetchData = () => setRequestFetch(true)

  useEffect(() => {
    const doFetch = async () => {
      setRequestFetch(false)
      setIsLoading(true);
      try {
        const result = await api(url);
        setData(result.data);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    doFetch();
  }, [url, requestFetch]);

  return [{ data: { value: data, setData }, loading: { value: loading, setIsLoading }, error: { value: error, setIsError }, fetchData, url }, setUrl];
};

export const useApiId = (type, initialId) => {
  let url = initialId ? `/${type}/${initialId}` : `/${type}`
  const [retObj, setUrl] = useApiRequest(url)
  const setId = (id) => setUrl(id ? `/${type}/${id}`: `/${type}`)
  return [retObj, setId]
};

export const trimString = (value, len) => {

  if (value.length > len) {
    value = value.substring(0, len) + "..."
  }

  return value
}

export const loadingSpinner = (text) => {
  return (
    <>
      <Text pb={4} fontSize="lg" textAlign="center">
        {text}
      </Text>
      <Flex justifyContent="center" >
        <Spinner size="lg"/>
      </Flex>
    </>
  )
}

export const errorText = (text) => {
  return (
    <>
      <Flex pb={4} justifyContent="center" >
        <Icon name="warning" size="24px" color="red.500" />
      </Flex>
      <Text fontSize="lg" color="red.500" fontWeight="900" textAlign="center">
        {text}
      </Text>
    </>
  )
}

export const toLocalDatetimeString = (date) => {
  const formDate = new Date(date)
    .toISOString()
    .substr(0, 10);
  const t = new Date(date)
  const formTime = new Date(t.getTime() - (t.getTimezoneOffset() * 60000))
    .toISOString()
    .substr(11, 5);
  return `${formDate} - ${formTime}`
}

export const tagBadges = (tags) => {
  if (tags) {
    return (
      <Box>
        {tags.map((tag, i) => (<Badge mr={2} key={i} variantColor="blue" >{tag?.name}</Badge>))}
      </Box>
    )
  } else {
    return (
      <Box>
      </Box>
    )
  }
}

export const eventsBadge = (events) => {
  if (events.length) {
    return (
      <Badge mr={4} variantColor="red" >Events: {events.length}</Badge>
    )
  } else {
    return (
      <></>
    )
  }
}

export const filterTest = (id, filterId, filterMask, filterInvert) => {
  console.log(filterId, filterMask, filterInvert)
  return (((id & filterMask) === filterId) ^ filterInvert)
}

export const browserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const userButtons = (user, logout) => {
  return (
    <Stack position="absolute" top={10} right={8} isInline>
      <Link href="/user"><Button {...buttonSettings} size="sm" aria-label="User profile" icon="download" variant="outline" variantColor="red">{user}</Button></Link>
      <Button {...buttonSettings} size="sm" aria-label="Logout" variant="solid" variantColor="red" onClick={() => logout()} >Logout</Button>
    </Stack>
  )
}

export const processColour = (state_value) => {
  if (state_value > 0) {
    return "purple"
  } else if (state_value === 0) {
    return "green"
  } else if (state_value === -1) {
    return "blue"
  } else {
    return "red"
  }
}
