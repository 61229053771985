import React from 'react';

import {
  PseudoBox, Progress,
  IconButton, Grid,
  Text} from "@chakra-ui/core";

import { api, loadingSpinner, errorText } from './Utility'
import { buttonSettings } from './Styles'

export function ProcessState({file, setThreadState, abortThread}) {
  const [overall, setOverall] = React.useState(0)
  const [state, setState] = React.useState('loading')
  const [tick, setTick] = React.useState(0)
  const thread = file.thread

  React.useEffect(() => {

    const getProgress = () => {
      api.get(`process/log/progress/${thread}`)
        .then((req) => {

          setOverall(req.data.process.overall)

          if (state !== req.data.process.state) {
            setThreadState(req.data.process.state)
            setState(req.data.process.state)
          }

          // tick forces update even if progress didn't change
          if (overall < 100) {
            setTick(tick + 1)
          }

        })
        .catch((e) => {
          setOverall(100)
          if (e.response && e.response.status === 400) {
            setState('expired')
            setThreadState('expired')
          } else {
            setState('unknown')
            setThreadState('unknown')
          }
        })
    }

    // poll the thread until it's done
    if ((overall < 100 && state !== 'abort') || state === 'transfering') {
      const timer = setTimeout(() => getProgress(), 500)
      return () => clearTimeout(timer)
    }

  }, [thread, overall, tick, state, setThreadState]);

  let progress_props = {
    color: "red",
    size: "md",
    value: overall,
    hasStripe: false
  }

  let progress_text = {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontSize: "sm",
    fontWeight: "bold",
    color: "black"
  }

  if (state === 'abort' || state === 'error' || state === 'expired') {
    progress_props.hasStripe = true
    progress_text.color = "red.400"
  }

  if (state === 'loading') {
    return (
      <PseudoBox>
        {loadingSpinner('loading thread progress')}
      </PseudoBox>
    )
  } else if (state === 'expired') {
    return (
      <PseudoBox>
        {errorText(`Thread ${file.thread} is expired!`)}
      </PseudoBox>
    )
  } else {
    return (
      <PseudoBox>
        <Grid alignItems="center" gap={2} templateColumns="20fr 1fr" >
          <Text mb={2} {...progress_text} >{state}</Text>
          <PseudoBox>
            <IconButton {...buttonSettings}
              size="xs"
              mb={2}
              variant="outline"
              alignSelf="right"
              variantColor="red"
              aria-label="Abort processing"
              icon="small-close"
              onClick={ () => abortThread(thread) }
            ></IconButton>
          </PseudoBox>
        </Grid>

        <Progress {...progress_props} />

      </PseudoBox>
    )
  }
}
