import React from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";

import {
  PseudoBox, Box, Flex,
  Stat, StatHelpText,
  Button,
  Heading,
  useDisclosure,
  Divider, Link,
  Stack, IconButton
} from "@chakra-ui/core";

import { api, useApiId, loadingSpinner, errorText } from './Utility'
import { buttonSettings } from './Styles'
import { ProcessState } from './ProcessState'
import ConfirmDialog from './ConfirmDialog'

export default function ViewFit({setToastMessage}) {
  const { id } = useParams();
  const history = useHistory();
  const [{ data, loading, error }] = useApiId('fits', id);
  const [ threadState, setThreadState ] = React.useState('unknown');
  const [ lastThreadState, setLastThreadState ] = React.useState('unknown');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ confirmDialog, setConfirmDialog ] = React.useState({ openConfirm: onClose });

  React.useEffect(() => {
    const update = () => {
      api.get(`fits/${id}`).then((res) => {
        data.setData(res.data)
      })
    }

    // update on state change from process
    if (lastThreadState !== threadState) {
      update();
      setLastThreadState(threadState)
    }

  }, [id, data, threadState, lastThreadState, setLastThreadState])

  const fetch = () => {
    api.get(`fits/${id}`).then((res) => {
      data.setData(res.data)
    })
  }

  const abortThread = (thread) => {
    api.get(`/process/log/abort/${thread}`).then(() => {
      fetch();
      setThreadState('unknown')
      setToastMessage({title: 'Processing Aborted', status: 'success', duration: 3000})

    }).catch(() => {
      fetch();
      setToastMessage({title: 'Abort Failed', status: 'error', duration: 5000})
    })
  }

  const confirmDelete = () => {
    setConfirmDialog(() => ({ heading: `Delete file ${data.value.id}`, onConfirm: () => { onClose(); deleteFile(data.value.id)} }))
    onOpen()
  }

  const deleteFile = (id) => {
    // confirm the sequence has an id (it should!)
    api.delete(`/fits/${id}`).then(() => {
      history.push("/import/fit");
      setToastMessage({title: 'File Deleted', description: `File ID ${id} deleted successfully`, status: 'success', duration: 3000})
    }).catch((err) => {
      fetch();
      console.log(err)
      setToastMessage({title: 'Delete File Failed', description: `Something went wrong deleting ID ${id}, please try again`, status: 'error', duration: 5000})
    })
    onClose()
  }

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading fit file details...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading or updating fit file details!')}
      </>
    )
  } else {
    return (
      <Box>
        <ConfirmDialog isOpen={isOpen} onClose={onClose} {...confirmDialog} />

        <PseudoBox>

          <Stat backgroundColor="gray.100" p={8} mb={4}>
            <Heading mb={2} as="h3" size="md" textTransform="uppercase">{data.value.id} - {data.value.server_filename}</Heading>
            <StatHelpText mb={0}>Server file: {data.value.server_filename}</StatHelpText>
            <StatHelpText mb={0}>UUID: {data.value.uuid || "unknown"}</StatHelpText>
            <StatHelpText mb={0}>Period: {data.value?.state === "done" ? `${data.value.start} - ${data.value.end}`: "unknown"}</StatHelpText>
            <Divider borderColor="red.200" />
            <StatHelpText mb={0}>Manufacturer: {data.value?.manufacturer || "unknown"}</StatHelpText>
            <StatHelpText mb={0}>Product name: {data.value?.product_name || "unknown"}</StatHelpText>
            <StatHelpText mb={0}>Serial number: {data.value?.serial_number || "unknown"}</StatHelpText>
            <Divider borderColor="red.200" />
            <Heading mb={2} as="h3" size="md" textTransform="uppercase">InfluxDB Import</Heading>
            <ImportInfo file={data.value} setThreadState={setThreadState} abortThread={abortThread} />
          </Stat>

        </PseudoBox>

        <Flex justifyContent="right" alignContent="right">
          <Stack mt={8} spacing={4} isInline >
            <IconButton {...buttonSettings}
              size="lg"
              variant="outline"
              variantColor="red"
              aria-label="Delete file"
              icon="delete"
              onClick={() => confirmDelete() }
            ></IconButton>
          </Stack>
        </Flex>

        <Flex mt={8} alignContent="center" justifyContent="center">
          <Link href='/import/fit'>
          <Button {...buttonSettings}
            size="lg"
            variant="solid"
            variantColor="red"
            >New Import</Button>
          </Link>
        </Flex>

      </Box>
    )
  }
}

function ImportInfo({file, setThreadState, abortThread}) {
  if (file.thread > 0) {
    return (<ProcessState file={file} setThreadState={setThreadState} abortThread={abortThread} />)
  } else {
    return (<Link href={file.link} isExternal><Button {...buttonSettings} size="sm" variant="solid" variantColor="red" rightIcon="external-link">Open Grafana</Button></Link>)
  }
}
