export const sublteText = {
  color: "gray.400",
  fontSize: "0.8em"
}

export const labelSettings = {
  // mb: "4",
  textTransform: "uppercase",
  fontWeight: "bold",
}

export const inputSettings = {
  mb: "4",
  borderColor: "gray.200",
  borderRadius: 0,
  focusBorderColor: "black",
}

export const checkboxSettings = {
  size: "lg",
  variantColor: "gray"
}

export const mainStringSettings = {
  ...inputSettings,
  variant: "filled",
  backgroundColor: "black",
  color: "white",
  _hover: {
    color: "black",
    borderColor: "gray.200", 
    bg: "gray.200"
  },
  _focus: {
    color: "black",
    outline: "none",
    bg: "white",
    boxShadow: "0 0 0 2px rgba(0,0,0,0.4)",
    borderColor: "gray.300",
  },
  size: "lg"
}

export const subHeading = {
  mb: 6,
  textAlign: "left",
  overflow: "hidden",
  fontSize: { base: "2rem", md: "2.25rem" },
  lineHeight: 1.25,
  fontWeight: 600,
  textTransform: "uppercase",
}

export const buttonSettings = {
  borderRadius: 0,
  textTransform: "uppercase",
}
