import React from 'react';
import {
  useHistory
} from "react-router-dom";

import {
  Box, Grid, PseudoBox, Flex,
  Button, IconButton, Badge,
  Tooltip,
  List, Link, 
  Text
} from "@chakra-ui/core";

import { api, useApiRequest, loadingSpinner, errorText, tagBadges, eventsBadge } from './Utility'
import { buttonSettings } from './Styles'

const FileItem = ({file, history}) => {
  return (
    <PseudoBox width="100%" borderColor="black" backgroundColor="white" borderBottom={"1px"} _first={{ borderTop: "1px" }} mb={0} >
      <Grid alignItems="center" templateColumns="10fr 10fr 1fr 1fr 1fr" p={1} gap={2}>
        <Tooltip label={file.description || "No description"} placement="right">
          <Box justifySelf="left" ><Text maxWidth={{ base: "10vw", sm: "20vw", lg: "20vw", md: "20vw" }} ml="0.5em" fontSize="lg" isTruncated textTransform="uppercase" fontStyle={file.title ? "normal" : "italic"}>{file.id}. {file.title || file.upload_filename}</Text></Box>
        </Tooltip>
        <Box isInline={true} overflow="hidden">
          <Box isInline={false}>
            <Badge mr={2} >{file.can_type !== "" && file.can_type ? file.can_type : file.state}</Badge>
            {eventsBadge(file.events)}
          </Box>
          {tagBadges(file.tags)}
        </Box>
        <IconButton title="Edit file" aria-label="Edit file" variant="ghost" fontSize="20px" icon="edit" size="md" onClick={() => history.push(`/edit/log/${file.id}`)} />
        <Link isExternal href={file.link} ><IconButton title="Open in Grafana" aria-label="Grafana URL" isDisabled={file.link === "" || file.link === undefined || file.archived || file.import_expired} variant="ghost" fontSize="20px" icon="external-link" size="md" /></Link>
        <Link isExternal href={`/uploads/${encodeURI(file.server_filename)}`} ><IconButton title="Download .zip" aria-label="Download" isDisabled={file.state.toLowerCase() === "processing" || file.archived} variant="ghost" fontSize="20px" icon="download" size="md" /></Link>
      </Grid>
    </PseudoBox>)
}

const FileList = ({files, history}) => {
  return (
    <List mb={2}>
      {files.map((file, i) => (<FileItem key={i} file={file} history={history} />))}
    </List>
  )
}

export default function CanLogList() {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [{ data, loading, error }] = useApiRequest(`/logs?page=0&num=8`);
  const [getMore, setGetMore] = React.useState(false)
  const [noMore, setNoMore] = React.useState(false)

  React.useEffect(() => {
    if (data.value) {
      if (data.value.length < 8) {
        setNoMore(true)
      }
    }
  }, [data, setNoMore])

  const requestMore = () => {
    var req = page + 1
    setPage(req)
    setGetMore(true)
    api.get(`/logs?page=${req}&num=8`).then((res) => {
      setGetMore(false)
      if (res.data.length > 0) {
        var new_data = data.value.concat(res.data)
        data.setData(new_data)
        if (res.data.length < 8) {
          setNoMore(true)
        }
      } else {
        setNoMore(true)
      }
    }).catch((err) => {
      setGetMore(false)
      setNoMore(true)
      console.log(err)
    })
  }

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading SBC-CAN log files...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading log files!')}
      </>
    )
  } else {
    if (data.value.length === 0) {
      return (
        <PseudoBox>
          <Text>Nothing to see here!</Text>
        </PseudoBox>
      )
    } else {
      return (
        <PseudoBox>
          <Grid borderBottom="solid 1px black" mb={4} alignItems="left" templateColumns="10fr 10fr 4fr" px={4} py={1} gap={2}>
            <Text>ID. Title</Text>
            <Text>Tags</Text>
            <Text textAlign="right" >Operations</Text>
          </Grid>
          <FileList files={data.value} history={history} />
          <Flex justifyContent="center" alignContent="center">
            <Button mt={4} {...buttonSettings} isLoading={getMore} isDisabled={noMore} title="Load more files" variantColor="red" onClick={() => requestMore()} >More</Button>
          </Flex>
        </PseudoBox>
      )
    }
  }
}
