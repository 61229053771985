import React from 'react';

import {
  Stat, StatHelpText,
  Heading,
  Divider,
  Link, Button,
  Box, Stack, Badge, PseudoBox
} from "@chakra-ui/core";

import { tagBadges, processColour } from './Utility'
import { buttonSettings } from './Styles'

const getEventLinks = (log) => {
  const {events_processed, events, events_filename, server_filename} = log

  let event_links = []

  // if events have been processed
  if (events_processed) {
    // if there are events
    if (events.length) {
      // make links for each timestamp
      event_links = events.map((e, i) => (
        <Link key={`el-${i}`} href={e.link} isExternal><Button key={`el-${i}`} {...buttonSettings} mr={2} mb={2} size="xs" variant="outline" variantColor="red" rightIcon="external-link">E{i+1}: {e.datetime.substring(10,19)}</Button></Link>)
      )
      // add a download file at end if there is one
      if (events_filename !== null && events_filename !== server_filename) {
        event_links.push(
          <Link key='el-download' isExternal href={`/uploads/${encodeURI(events_filename)}`} >
            <Button {...buttonSettings} mr={2} mb={2} size="xs" variant="outline" variantColor="red" rightIcon="download">Download events</Button></Link>
        )
      }
    } else {
      event_links.push(
        <Button key="no-events" {...buttonSettings} isDisabled={true} mr={2} mb={2} size="xs" variant="outline" variantColor="red" rightIcon="not-allowed">No Events Found</Button>
      )
    }
  }

  return event_links
}

const processedData = (log) => {

  if (log.state_value === 0 || log.link) {
    return (
      <>
        <Divider borderColor="red.200" />
        <Stack>
          <Box>
            <Link href={log.link} isExternal><Button {...buttonSettings} size="xs" variant="solid" variantColor="red" rightIcon="external-link" isDisabled={!log.link || log.archived || log.import_expired} >Open Grafana</Button></Link>
          </Box>
          <Box>
            {getEventLinks(log)}
          </Box>
        </Stack>
      </>
    )
  }

}

export const LogStats = ( {log} ) => {
    // logger data
    var logger_id = log.logger_id
    var logger_version = log.logger_version
    var logger_speed_threshold = log.logger_speed_threshold
    if (logger_id === null || logger_id === -1) {
      logger_id = 'Unknown'
    }
    if (!logger_version) {
      logger_version = '< 1.4'
    }
    if (logger_speed_threshold === null || logger_speed_threshold === -1) {
      logger_speed_threshold = 'Unknown'
    }

    // dates from log start end
    var start = new Date(log.start)
    var end = new Date(log.end)

    var state_badge
    var archived_badge
    var opacity = 1.0

    if (log.state_value !== 0) {
      state_badge = <Badge variantColor={processColour(log.state_value)} fontSize="md" >{log.state}</Badge>
    }

    if (log.archived) {
      archived_badge = <Badge ml={2} variantColor="yellow" fontSize="md" >Archived</Badge>
      opacity = 0.75
    }

    var converter_style

    if (log.converted) {
      converter_style = {
        "fontWeight": "bold"
      }
    }

    return (
        <Stat backgroundColor="gray.100" opacity={opacity} p={8} mb={4}>
          <Stack isInline>
            <Heading mb={2} as="h3" size="md" textTransform="uppercase">{log.id} - {log.title || log.upload_filename}</Heading>
            <PseudoBox>
              {state_badge}
              {archived_badge}
            </PseudoBox>
          </Stack>
          <StatHelpText mb={0}>Upload file: {log.upload_filename}</StatHelpText>
          <StatHelpText mb={0}>Server file: {log.server_filename}</StatHelpText>
          <StatHelpText {...converter_style} mb={0}>Current filetype: {log.filetype}</StatHelpText>
          <StatHelpText mb={0}>UUID: {log.uuid}</StatHelpText>
          <StatHelpText mb={0}>Period: {start.toString()} -&gt; {end.toTimeString().substring(0,8)}</StatHelpText>
          <StatHelpText mb={0}>Type: {log.can_type || 'Not processed'}</StatHelpText>
          <StatHelpText mb={0}>DBC file: {log.dbc || 'Not processed'}</StatHelpText>
          {tagBadges(log.tags)}
          <Divider borderColor="red.200" />
          <StatHelpText mb={0}>Logger ID: {logger_id}</StatHelpText>
          <StatHelpText mb={0}>Logger version: {logger_version}</StatHelpText>
          <StatHelpText mb={0}>Logger speed threshold: {logger_speed_threshold}</StatHelpText>
          {processedData(log)}
        </Stat>
    )
}
