import React from 'react';
import {
  useHistory,
} from "react-router-dom";

import {
  Input,
  InputGroup,
  InputLeftElement,
  Icon
} from "@chakra-ui/core";

import { inputSettings } from './Styles'

export default function SearchLogBox() {
  const history = useHistory()

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push(`/search/logs?q=${event.target.value}`);
    }
  }

  return (
    <InputGroup>
      <InputLeftElement
      pointerEvents="none"
      children={<Icon name="search" color="gray.300" />}
      />
      <Input {...inputSettings} type="text" placeholder="Search Log Files" onKeyDown={handleKeyDown} />
    </InputGroup>
  )
}
