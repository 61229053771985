import React from 'react';
import {
  useLocation,
} from "react-router-dom";
import queryString from 'query-string';

import {
  PseudoBox,
  Text
} from "@chakra-ui/core";

import ManualCanLogList from './ManualCanLogList'
import SearchLogBox from './SearchLogBox'
import { useApiRequest, loadingSpinner, errorText } from './Utility'

export default function SearchResults() {
  let query = queryString.parse(useLocation().search);
  const [{ data, loading, error }, setUrl] = useApiRequest(`/search/logs?q=${query.q}`);

  React.useEffect(() => {
    setUrl(`/search/logs?q=${query.q}`)
  }, [query, setUrl])

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading SBC-CAN log files...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading log files!')}
      </>
    )
  } else {
    if (data.value.length === 0) {
      return (
        <PseudoBox>
          <SearchLogBox />
          <Text>No results.</Text>
        </PseudoBox>
      )
    } else {
      return (
        <PseudoBox>
          <SearchLogBox />
          <ManualCanLogList data={data.value} />
        </PseudoBox>
      )
    }
  }
}
