import React from 'react';
import { Grid, Image, Text, } from "@chakra-ui/core";

import { sublteText } from './Styles'

import logo from './../assets/logo.png';

export default function Footer(props) {
  if (props.logo) {
    return(
      <Grid justifyContent="center" templateColumns={{ md: "1fr 2fr 1fr" }} gridColumnGap={4} gridRowGap={4} >
        <Text {...sublteText} textAlign={{base: "center", md: "left"}} alignSelf="center" >{props.left}</Text>
        <Image m="auto" height={50} src={logo} />
        <Text {...sublteText} textAlign={{base: "center", md: "right"}} alignSelf="center" >{props.right}</Text>
      </Grid>
    )
  } else {
    return(
      <Grid justifyContent="center" templateColumns={{ md: "1fr 2fr 1fr" }} gridColumnGap={4} gridRowGap={4} >
        <Text {...sublteText} textAlign={{base: "center", md: "left"}} alignSelf="center" >{props.left}</Text>
        <Text textAlign={{base: "center", md: "center"}} alignSelf="center" >{props.centre}</Text>
        <Text {...sublteText} textAlign={{base: "center", md: "right"}} alignSelf="center" >{props.right}</Text>
      </Grid>
    )
  }
}
