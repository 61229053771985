import React from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";

import {
  PseudoBox, Flex, Stack,
  Stat, FormErrorMessage,
  FormLabel,
  FormControl,
  Input, InputGroup, InputLeftAddon, Select, Checkbox,
  useDisclosure,
  Button, IconButton,
  Heading,
  Link
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";

import { api, useApiId, loadingSpinner, errorText } from './Utility'
import ConfirmDialog from './ConfirmDialog'
import { buttonSettings, labelSettings, inputSettings, checkboxSettings } from './Styles'
import { LogStats } from './LogStats'
import { ProcessState } from './ProcessState'

export default function EditLog({setToastMessage}) {
  const { id } = useParams();
  const history = useHistory();
  const { handleSubmit, errors, register, formState, reset } = useForm();
  const [{ data, loading, error }] = useApiId('logs', id);
  const [submitting, setSubmitting] = React.useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ confirmDialog, setConfirmDialog ] = React.useState({ openConfirm: onClose });
  const [ formDisabled, setFormDisabled ] = React.useState(true);

  const [ threadState, setThreadState ] = React.useState('unknown');
  const [ lastThreadState, setLastThreadState ] = React.useState('unknown');

  const onSubmit = (d) => {
    let patch = Object.assign({}, data.value);
    patch.title = d.title
    patch.description = d.description
    patch.can_type = d.can_type
    patch.do_not_archive = d.do_not_archive
    patch.do_not_unprocess = d.do_not_unprocess

    // convert tags csv to array of tag objects
    let tags = d.tags.split(',')
    patch.tags = []
    tags.forEach((name) => {
      if (name && name.length <= 20) {
        patch.tags.push({ name: name.trim() })
      }
    })

    // re-fetech id in case tags have updated
    setSubmitting(true)

    api.patch(`logs`, patch).then((res) => {
      setSubmitting(false)
      console.log(res.data)
      // clear the global logfile
      data.setData(res.data)
      // delete tags and rely on input data because they will not reset nicely
      let resetData = Object.assign({}, res.data)
      delete resetData.tags
      reset(resetData)
      setToastMessage({title: 'File Saved',  status: 'success', duration: 3000})
    }).catch(() => {
      setSubmitting(false)
      setToastMessage({title: 'Save Failed', description: `Something went wrong saving the file details, please try again`, status: 'error', duration: 5000})
    })
  }

  // HACK: this is calling the api rather than the fetchData to avoid changing loading state and thus re-rendering form/page
  const fetch = () => {
    api.get(`logs/${id}`).then((res) => {
      data.setData(res.data)
    })
  }

  React.useEffect(() => {
    const update = () => {
      api.get(`logs/${id}`).then((res) => {
        data.setData(res.data)
      })
    }

    // disable form whilst decoding as tags might change
    if (threadState === 'decoding' || threadState === 'detecting') {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }

    // update on state change from process
    if (lastThreadState !== threadState) {
      update();
      setLastThreadState(threadState)
    }

  }, [id, data, threadState, lastThreadState, setLastThreadState])

  const confirmDelete = () => {
    setConfirmDialog(() => ({ heading: `Delete file ${data.value.id}`, onConfirm: () => { onClose(); deleteFile(data.value.id)} }))
    onOpen()
  }

  const confirmConvert = () => {
    let pre_text = "Will convert"
    if (data.value.converted) {
      pre_text = "Will re-convert"
    }
    setConfirmDialog(() => ({ heading: `Convert ${data.value.id}`, body: `${pre_text} ${data.value.filetype} log filetype: ${data.value.current_ext} -> ${data.value.convert_exts[0]}`, onConfirm: () => { onClose(); convertLog(data.value.id)} }))
    onOpen()
  }

  const deleteFile = (id) => {
    // confirm the sequence has an id (it should!)
    setSubmitting(true)
    api.delete(`/logs/${id}`).then(() => {
      history.push("/import/log");
      setToastMessage({title: 'File Deleted', description: `File ID ${id} deleted successfully`, status: 'success', duration: 3000})
    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Delete File Failed', description: `Something went wrong deleting ID ${id}, please try again`, status: 'error', duration: 5000})
    })
    onClose()
  }

  const processLog = () => {
    const id = data.value.id
    // confirm the sequence has an id (it should!)
    setSubmitting(true)

    api.get(`/process/log/${id}?events`).then(() => {
      fetch();
      setSubmitting(false)
      setThreadState('unknown')
      setToastMessage({title: 'File Processing', description: `Started processing ID ${id}`, status: 'success', duration: 3000})

    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Fail Start', description: `Something went starting process for ID ${id}, please try again`, status: 'error', duration: 5000})
    })
  }

  const findEvents = () => {
    const id = data.value.id
    // confirm the sequence has an id (it should!)
    setSubmitting(true)

    api.get(`/find_events/log/${id}`).then(() => {
      fetch();
      setThreadState('unknown')
      setSubmitting(false)
      setToastMessage({title: 'Finding Events', description: `Started processing ID ${id}`, status: 'success', duration: 3000})

    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Fail Start', description: `Something went starting process for ID ${id}, please try again`, status: 'error', duration: 5000})
    })
  }

  const convertLog = (id) => {
    // confirm the sequence has an id (it should!)
    setSubmitting(true)

    api.get(`/convert/log/${id}`).then(() => {
      fetch();
      setThreadState('unknown')
      setSubmitting(false)
      setToastMessage({title: 'Converting Log', description: `Started processing ID ${id}`, status: 'success', duration: 3000})

    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Fail Start', description: `Something went starting process for ID ${id}, please try again`, status: 'error', duration: 5000})
    })
  }

  const addTraceFile = (id) => {
    // confirm the sequence has an id (it should!)
    setSubmitting(true)

    api.get(`/convert/log/${id}?to_trace=1`).then(() => {
      fetch();
      setThreadState('unknown')
      setSubmitting(false)
      setToastMessage({title: 'Adding PCAN .trc to .zip download', description: `Started processing ID ${id}`, status: 'success', duration: 3000})

    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Fail Start', description: `Something went starting process for ID ${id}, please try again`, status: 'error', duration: 5000})
    })
  }

  const abortThread = (thread) => {
    // confirm the sequence has an id (it should!)
    setSubmitting(true)

    api.get(`/process/log/abort/${thread}`).then(() => {
      fetch();
      setSubmitting(false)
      setThreadState('unknown')
      setToastMessage({title: 'Processing Aborted', status: 'success', duration: 3000})

    }).catch((err) => {
      fetch();
      console.log(err)
      setSubmitting(false)
      setToastMessage({title: 'Abort Failed', status: 'error', duration: 5000})
    })
  }

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading logfile details...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading or updating logfile details!')}
      </>
    )
  } else {

    var tagsString = ""
    if (data.value.tags) {
      data.value.tags.map((tag) => tagsString += `${tag?.name},`)
      tagsString = tagsString.substring(0, tagsString.length - 1)
    }

    var add_trc_title = "Add PCAN .trc to .zip download"

    if (data.value.has_trc) {
      add_trc_title = ".zip already has PCAN .trc"
    }

    const canTypes = data.value.can_types
    let canTypeOptions = []
    canTypes.map((t, i) => {
      return canTypeOptions.push(
        <option key={`t-select-${i}`} value={t}>{t}</option>
      )
    })

    let checkBoxes = []
    if (data.value.unprocess_date) {
        checkBoxes.push(<Checkbox {...inputSettings} {...checkboxSettings} key="do_not_process" isDisabled={data.value.state === "unprocessed"} name="do_not_unprocess" defaultIsChecked={data.value.do_not_unprocess} ref={register()}>{data.value.unprocess_date === null ? `Do not unprocess` : `Do not unprocess after ${data.value.unprocess_date}`}</Checkbox>)
    }
    if (data.value.archive_date) {
      checkBoxes.push(<Checkbox {...inputSettings} {...checkboxSettings} key="do_not_archive" isDisabled={data.value.archived} name="do_not_archive" defaultIsChecked={data.value.do_not_archive} ref={register()}>{data.value.archive_date === null ? `Do not archive` : `Do not archive after ${data.value.archive_date}`}</Checkbox>)
    }

    return (
      <PseudoBox>
        <ConfirmDialog isOpen={isOpen} onClose={onClose} {...confirmDialog} />

        <LogStats log={data.value} />

        <Processing file={data.value} thread={data.value.thread} setThreadState={setThreadState} processLog={processLog} findEvents={findEvents} abortThread={abortThread} onDone={fetch} />

        <form onSubmit={handleSubmit(onSubmit)}>

          <FormControl isDisabled={formDisabled || !(data.value.can_type === "Unknown" || data.value.can_type === "")} isInvalid={errors.can_type}>
            <FormLabel {...labelSettings} htmlFor="title">CAN Type</FormLabel>
            <Select {...inputSettings} defaultValue={data.value.can_type || "Unknown"} name="can_type" ref={register()}>
              {canTypeOptions}
            </Select>
            <FormErrorMessage>
              {errors.can_type && "Invalid CAN type"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isDisabled={formDisabled} isInvalid={errors.title}>
            <FormLabel {...labelSettings} htmlFor="title">Title</FormLabel>
            <Input {...inputSettings}
              name="title"
              defaultValue={data.value.title}
              ref={register({ required: false, maxLength: 100 })}
            />
            <FormErrorMessage>
              {errors.title && "Invalid title"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isDisabled={formDisabled} isInvalid={errors.description}>
            <FormLabel {...labelSettings} htmlFor="description">Description</FormLabel>
            <Input {...inputSettings}
              name="description"
              defaultValue={data.value.description}
              ref={register({ required: false, maxLength: 1024 })}
            />
            <FormErrorMessage>
              {errors.description && "Description too long"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isDisabled={formDisabled} isInvalid={errors.tags}>
            <FormLabel {...labelSettings} htmlFor="tags">Tags</FormLabel>
            <InputGroup size="sm">
              <InputLeftAddon children="TAG,TAG" />
              <Input {...inputSettings}
                name="tags"
                defaultValue={tagsString}
                ref={register({ required: false, maxLength: 512, pattern: /^[A-Za-z0-9,_-]+$/i })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.tags && "Invalid tags"}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing={4} isInline>
            {checkBoxes}
          </Stack>

          <Flex justifyContent="right" alignContent="right">
            <Stack mt={8} spacing={4} isInline >
              <IconButton {...buttonSettings}
                size="lg"
                isDisabled={submitting}
                variant="outline"
                variantColor="red"
                aria-label="Delete file"
                icon="delete"
                title="Delete file"
                onClick={() => confirmDelete() }
              ></IconButton>
              <IconButton {...buttonSettings}
                size="lg"
                isDisabled={data.value.state_value > 0 || data.value.has_trc || data.value.archived}
                variant="outline"
                variantColor="gray"
                aria-label="Add PCAN .trc"
                title={add_trc_title}
                icon="plus-square"
                onClick={() => addTraceFile(data.value.id) }
              ></IconButton>
              <IconButton {...buttonSettings}
                size="lg"
                isDisabled={data.value.state_value > 0 || data.value.archived}
                variant="outline"
                variantColor="gray"
                aria-label="Convert log"
                title="Convert log"
                icon="repeat"
                onClick={() => confirmConvert() }
              ></IconButton>
              <Link isExternal href={`/uploads/${encodeURI(data.value.server_filename)}`} >
                <IconButton {...buttonSettings}
                  size="lg"
                  isDisabled={data.value.state_value > 0 || data.value.archived}
                  variant="solid"
                  variantColor="gray"
                  aria-label="Download file"
                  title="Download file"
                  icon="download"
                ></IconButton>
              </Link>
              <Button {...buttonSettings}
                size="lg"
                isLoading={submitting}
                isDisabled={!formState.dirty && !(data.value.can_type === "Unknown" || data.value.can_type === "")}
                variant="solid"
                variantColor="red"
                type="submit"
                title="Save edited file"
              >
                Save
              </Button>
            </Stack>
          </Flex>

        </form>

        <Flex mt={8} alignContent="center" justifyContent="center">
          <Link href='/import/log'>
          <Button {...buttonSettings}
            size="lg"
            variant="solid"
            variantColor="red"
            >New Import</Button>
          </Link>
        </Flex>

      </PseudoBox>
    )
  }
}

function Processing({file, setThreadState, processLog, findEvents, abortThread}) {
  const {state, events_processed, can_type} = file

  let inner_props = {
    mr: 2,
    mb: 2
  }

  let inner = []
  // if a process is active and there is a thread
  if (file.thread > 0) {
    inner.push(<ProcessState key="process-state" file={file} setThreadState={setThreadState} abortThread={abortThread} />)
  } else {
    // if not processed, have aborted or it failed detecting but now as a can_type
    if (state === "unprocessed" || state === "abort" || file.import_expired || 
        ((state === "faildetect" || state === "faildecode") && !(can_type === "unknown" || can_type === ""))) {
      // provide option to process
      inner.push(<Button key="process-log" {...buttonSettings} {...inner_props} onClick={() => processLog()} size="sm" variant="solid" variantColor="red" rightIcon="time">Process Log</Button>)
    } 

    if (!events_processed && state !== "failevents" && !(can_type === "unknown" || can_type === "")) {
      inner.push(<Button key="find-events" {...buttonSettings} {...inner_props} onClick={() => findEvents()} size="sm" variant="outline" variantColor="red" rightIcon="time">Find Events</Button>)
    }
  }

  // don't show if archived
  if (inner.length && !file.archived) {
    return (
      <Stat backgroundColor="gray.100" p={8} mb={4}>
        <Heading mb={2} as="h4" size="md" textTransform="uppercase">Processing</Heading>
        {inner}
      </Stat>
    )
  } else {
    return (<></>)
  }
}
