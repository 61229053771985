import React from 'react';

import {
  PseudoBox, Box,
  Tag,
  Stack,
  Stat, StatHelpText,
  Heading,
  Divider
} from "@chakra-ui/core";

import { useApiRequest, loadingSpinner, errorText } from './Utility'

export const UserInfo = ({user}) => {
  let adminTag

  if (user.admin) {
    adminTag = (
      <PseudoBox>
        <Tag size="sm" variantColor="red" >ADMIN</Tag>
      </PseudoBox>
    )
  }

  return (
    <PseudoBox>
      <Stat backgroundColor="gray.100" p={8} mb={4}>
        <Stack isInline>
          <Heading mb={2} as="h3" size="md" textTransform="uppercase">{user.id} - {user.name}</Heading>
          {adminTag}
        </Stack>
        <StatHelpText mb={0}>Logs: {user.canlogs.length}</StatHelpText>
        <StatHelpText mb={0}>Group: {user.group_id} - {user.group}</StatHelpText>
        <StatHelpText mb={0}>OrgID: {user.org_id}</StatHelpText>
        <StatHelpText mb={0}>Import DB: {user.import_db}</StatHelpText>
        <StatHelpText mb={0}>Timezone: {user.tz}</StatHelpText>
        <Divider borderColor="red.200" />
        <Heading mb={2} as="h4" size="md" textTransform="uppercase">API key</Heading>
        <Tag size="sm" variantColor="blue" overflow="hidden" >
          {user.api_key}
        </Tag>
      </Stat>
    </PseudoBox>
  )
}

export default function UserPage() {
  const [{ data, loading, error }] = useApiRequest('user');

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading user...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading user details!')}
      </>
    )
  } else {
    return (
      <Box>
        <UserInfo user={data.value} />
      </Box>
    )
  }
}
