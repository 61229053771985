import React from 'react';
import {
  useHistory
} from "react-router-dom";

import {
  Box, Grid, PseudoBox, Flex,
  Button, IconButton,
  List, Link, Text
} from "@chakra-ui/core";

import { api, useApiRequest, loadingSpinner, errorText } from './Utility'
import { buttonSettings } from './Styles'

const FileItem = ({file, history, type}) => {
  return (
    <PseudoBox width="100%" borderColor="black" backgroundColor="white" borderBottom={"1px"} _first={{ borderTop: "1px" }} mb={0} >
      <Grid alignItems="center" templateColumns="20fr 1fr 1fr" p={1} gap={2}>
        <Box justifySelf="left" ><Text maxWidth={{ base: "10vw", sm: "20vw", lg: "20vw", md: "20vw" }} ml="0.5em" fontSize="lg" isTruncated textTransform="uppercase">{file.id}. {file.server_filename}</Text></Box>
        <IconButton aria-label="View file" title="View file" variant="ghost" fontSize="20px" icon="view" size="md" onClick={() => history.push(`/view/${type}/${file.id}`)} />
        <Link isExternal href={file.link} ><IconButton aria-label="Grafana URL" title="Open in Grafana" isDisabled={file.link === ""} variant="ghost" fontSize="20px" icon="external-link" size="md" /></Link>
      </Grid>
    </PseudoBox>)
}

const FileList = ({files, history, type}) => {
  return (
    <List mb={2}>
      {files.map((file, i) => (<FileItem type={type} key={i} file={file} history={history} />))}
    </List>
  )
}

export default function RecentFiles({type}) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [{ data, loading, error }] = useApiRequest(`/${type}s?page=0&num=8`);
  const [getMore, setGetMore] = React.useState(false)
  const [noMore, setNoMore] = React.useState(false)

  React.useEffect(() => {
    if (data.value) {
      if (data.value.length < 8) {
        setNoMore(true)
      }
    }
  }, [data, setNoMore])

  const requestMore = () => {
    var req = page + 1
    setPage(req)
    setGetMore(true)
    api.get(`/${type}s?page=${req}&num=8`).then((res) => {
      setGetMore(false)
      if (res.data.length > 0) {
        var new_data = data.value.concat(res.data)
        data.setData(new_data)
        if (res.data.length < 8) {
          setNoMore(true)
        }
      } else {
        setNoMore(true)
      }
    }).catch((err) => {
      setGetMore(false)
      setNoMore(true)
      console.log(err)
    })
  }

  if (loading.value) {
    return (
      <>
        {loadingSpinner('Loading files...')}
      </>
    )
  } else if (error.value) {
    return (
      <>
        {errorText('Error loading files!')}
      </>
    )
  } else {
    if (data.value.length === 0) {
      return (
        <PseudoBox>
          <Text>Nothing to see here!</Text>
        </PseudoBox>
      )
    } else {
      return (
        <PseudoBox>
          <Grid borderBottom="solid 1px black" mb={4} alignItems="left" templateColumns="10fr 4fr" px={4} py={1} gap={2}>
            <Text>ID. Title</Text>
            <Text textAlign="right" >Operations</Text>
          </Grid>
          <FileList type={type} files={data.value} history={history} />
          <Flex justifyContent="center" alignContent="center">
            <Button mt={4} {...buttonSettings} title="Load more files" isLoading={getMore} isDisabled={noMore} variantColor="red" onClick={() => requestMore()} >More</Button>
          </Flex>
        </PseudoBox>
      )
    }
  }
}
